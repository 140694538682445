import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My <strong className="purple">Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Emotion Based Music Player"
              description="An AI program that uses computer vision and detects a person’s emotion and plays music accordingly"
              ghLink="https://github.com/AravinthSS07/EmotionBasedMusicPlayer"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Notler"
              description="A cloud-based notes application that works on any device that supports web 2 standards and synchronizes the notes across devices."
              ghLink="https://github.com/AravinthSS07/notler-v2"
              // demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Metaverse Game"
              description="A 3D game that is made to implement the concept of metaverse into it which is made to work on any kind of device and is made to work on WebGL standards."
              ghLink="https://creativebrandgurus.github.io/cbg_metaverse_unity_builds/"
              // demoLink="https://editor.soumya-jit.tech/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Nightmare Strider"
              description="A fast pace action 3D game which is built on Unity’s 3D engine along with unity’s technologies like URP. "
              // ghLink="https://github.com/soumyajit4419/Plant_AI"
              ghLink="https://blazedevelopers.web.app/nightmarestrider.html"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Streamlit UI"
              description="Developed a Graphical User Interface using Python and Qt5 for an open-source library known as Streamlit which is made for video encoding. "
              ghLink="https://github.com/AravinthSS07/streamlinkgui"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Chat Application"
              description="Made using React, and Firebase for real-time data transfer. "
              ghLink="https://chat.aravinthss07.me/"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="IoT Based Dustbin"
              description="An IoT based system that notifies the garbage collector vehicles when the dustbin is filled above 85% and resets when its emptied."
              ghLink=""
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
